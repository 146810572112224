import './FaqDetail.scss'

import { graphql, PageProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import WidgetBuilder from 'Shared/components/builders/WidgetBuilder'
import EasySoftwareBreadcrumbs, {
  BreadcrumbItem,
} from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import PuxColumns, {
  PuxMainColumn,
  PuxSideColumn,
} from 'Shared/components/PuxColumns/PuxColumns'
import { localizedPathType } from 'Shared/components/PuxLanguageSelector/PuxLanguageSelector'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import PuxMetaTags, { PuxMetaTagsType } from 'Shared/utils/PuxMetaTags'

import Layout from '../../components/core/Layout'

const rootClass = `faq-detail`

export interface FaqType {
  contentType: string
  path: string
  displayText: string
  localization: {
    localizations: localizedPathType[]
  }
  faqAnswer: PuxWysiwygHtmlBodyType
}

export interface FunctionDetailType extends PageProps {
  data: {
    orchard: {
      easySoftwareFaq: [FaqType]
    }
  }
  pageContext: {
    pagePath: string
    metaTags: PuxMetaTagsType
    breadcrumbs: [BreadcrumbItem]
    sideCardWidgets: any
  }
}

export const query = graphql`
  query($pagePath: String = "") {
    orchard {
      easySoftwareFaq(first: 1, where: { path: $pagePath }) {
        contentType
        path
        contentItemId
        displayText
        localization {
          localizations {
            ... on Orchard_EasySoftwareFaq {
              path
              published
              localization {
                culture
              }
            }
          }
        }
        faqAnswer {
          html
        }
      }
    }
  }
`

const FaqDetail: FunctionComponent<FunctionDetailType> = (props) => {
  const pageData = props.data.orchard.easySoftwareFaq[0]
  const breadCrumbsData = props.pageContext.breadcrumbs

  return (
    <Layout localizedPath={pageData.localization?.localizations}>
      <EasySoftwareBreadcrumbs items={breadCrumbsData} />
      <PuxMetaTags {...props.pageContext.metaTags} />
      <div className='pux-container'>
        <div className={`${rootClass}`}>
          <PuxColumns>
            <PuxMainColumn>
              <h1 className={`${rootClass}-title`}>{pageData.displayText}</h1>
              <PuxWysiwyg content={pageData.faqAnswer} />
            </PuxMainColumn>
            <PuxSideColumn>
              <div className='sidebar-sticky'>
                <WidgetBuilder
                  widgetBuilderData={props.pageContext.sideCardWidgets}
                />
              </div>
            </PuxSideColumn>
          </PuxColumns>
        </div>
      </div>
    </Layout>
  )
}

export default FaqDetail
